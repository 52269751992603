
import { defineComponent, reactive, ref, onMounted, UnwrapRef } from "vue";
import { notification } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import candidateService from "../services/candidate";
import {} from "@ant-design/icons-vue";
import moment, { Moment } from "moment";

interface IrefereeRequest {
  referee_relationship: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
  candidate_job: string;
  workstartmonth: string;
  workstartyear: string;
  workendmonth: string;
  workendyear: string;
  profile_id: [];
}

export default defineComponent({
  emits: ["CloseRefereeModal"],
  props: ["candidateId", "From", "accessToken"],
  components: {},
  setup(props, { emit }) {
    const candidateQprofiles = ref([]);
    const confirmLoading = ref<boolean>(false);
    const addRefereeFormRef = ref();
    const startYearOpen = ref<boolean>(false);
    const endYearOpen = ref<boolean>(false);
    const addRefereeRules = validate.addRefereeValidation;
    const addRefereeModal: UnwrapRef<IrefereeRequest> = reactive({
      referee_relationship: "",
      first_name: "",
      last_name: "",
      company: "",
      email: "",
      phone: "",
      candidate_job: "",
      workstartmonth: "",
      workstartyear: "",
      workendmonth: "",
      workendyear: "",
      profile_id: []
    });
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    // const disabledStartDate = (startValue: any) => {
    //   const endValue = addRefereeModal.workend;
    //   if (!startValue || !endValue) {
    //     return false;
    //   }
    //   return startValue.valueOf() >= endValue.valueOf();
    // };
    // const disabledEndDate = (endValue: any) => {
    //   const startValue = addRefereeModal.workstart;
    //   if (!endValue || !startValue) {
    //     return false;
    //   }
    //   return startValue.valueOf() >= endValue.valueOf();
    // };
    const sendNewReferee = async (value: any) => {
      confirmLoading.value = true;
      const d1d = moment().subtract(1, "days").format("DD");
      const d1M = moment(value.workstartmonth).format("MM");
      const d1y = moment(value.workstartyear).format("YYYY");
      value.work_start = d1y + "-" + d1M + "-" + d1d;
      const d2d = moment().subtract(1, "days").format("DD");
      const d2M = moment(value.workendmonth).format("MM");
      const d2y = moment(value.workendyear).format("YYYY");
      value.work_end = d2y + "-" + d2M + "-" + d2d;
      try {
        if (props.From === "webPage") {
          const res = await candidateService.addNewReferee(
            props.candidateId,
            value
          );
          if (res.data.data.success) {
            await notify("Success", res.data.data.message, "success");
            addRefereeFormRef.value.resetFields();
            emit("CloseRefereeModal");
          }
        }
        if (props.From === "candidatePage") {
          value.url_token = props.accessToken;
          value.candidate_id = props.candidateId;
          const res = await candidateService.webCandidateAddReferee(value);
          if (res.data.data.success) {
            await notify("Success", res.data.data.message, "success");
            addRefereeFormRef.value.resetFields();
            emit("CloseRefereeModal");
          }
        }
        confirmLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response)
          if (props.From === "webPage") {
            if(error.response.data.work_end){
              await notify("Error", error.response.data.work_end[0], "error");
            } else {
              await notify("Error", error.response.data.data.message, "error");
            }
          } else {
            if(error.response.data.work_end){
              await notify("Error", error.response.data.work_end[0], "error");
            } else {
              await notify("Error", error.response.data.message, "error");
            }
          }
        }
        confirmLoading.value = false;
      }
    };
    const addNewReferee = async () => {
      addRefereeFormRef.value
        .validate()
        .then(() => {
          sendNewReferee(addRefereeModal);
        })
        .catch((error: ValidateErrorEntity<IrefereeRequest>) => {
          console.log("error", error);
        });
    };
    const cancelAddReferee = async () => {
      emit("CloseRefereeModal", "CloseModal");
    };
    const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const handlePanelChange1 = (val: Moment[], mode: string) => {
      addRefereeModal.workstartyear = val.toString();
      startYearOpen.value = false;
      setTimeout(() => {
        addRefereeFormRef.value
          .validate('workstartyear')
      }, 100)
    };
    const getfocus1 = () => {
      startYearOpen.value = true;
    };
    const handlePanelChange2 = (val: Moment[], mode: string) => {
      addRefereeModal.workendyear = val.toString();
      endYearOpen.value = false;
       setTimeout(() => {
        addRefereeFormRef.value
          .validate('workendyear')
      }, 100)
    };
    const getfocus2 = () => {
      endYearOpen.value = true;
    };
    const getCandidateQProfile = async (candidateId: any) => {
      candidateQprofiles.value = [];
      try {
        const res = await candidateService.getcandidateQuestionProfiles(candidateId);
        if (res.data.data.success) {
          candidateQprofiles.value = res.data.data.candidate_profiles;
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    onMounted(() => {
      getCandidateQProfile(props.candidateId)
    });
    return {
      startYearOpen,
      endYearOpen,
      handlePanelChange1,
      handlePanelChange2,
      getfocus1,
      getfocus2,
      addRefereeFormRef,
      addRefereeModal,
      addRefereeRules,
      confirmLoading,
      addNewReferee,
      filterOption,
      notify,
      cancelAddReferee,
      // disabledStartDate,
      // disabledEndDate,
      labelCol: {
        lg: {span: 10},
        md:{span: 24},
        sm:{span: 24}
      },
      wrapperCol: {
        lg: {span: 14},
        md:{span: 24},
        sm:{span: 24}
      },
      candidateQprofiles,
    };
  },
});
